<template>
  <bg-modal
    :value="isOpen"
    :title="modalTitle"
    :description="modalDescription"
    desktop-size="sm"
    :closable="false"
    :close-on-click-backdrop="false"
  >
    <template #footer>
      <div class="flex justify-end">
        <bg-button :loading="isLoading" class="mr-16" @click="whichOne"
          >Hapus</bg-button
        >
        <bg-button variant="primary" @click="closeModal">Batal</bg-button>
      </div>
    </template>
  </bg-modal>
</template>

<script>
import { BgModal, BgButton } from 'bangul-vue';
import { endpoints } from '@admin_endpoints/detail-transfer';

export default {
  name: 'DeleteOperationalModal',

  components: {
    BgModal,
    BgButton,
  },

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    costId: {
      type: [String, Number],
      default: null,
    },
    isAdditionalIncome: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isLoading: false,
    };
  },

  computed: {
    approvalId() {
      return this.$route.params.approval_id;
    },
    modalTitle() {
      return this.isAdditionalIncome
        ? 'Yakin menghapus biaya tambahan pendapatan?'
        : 'Yakin menghapus biaya pengurangan operasional?';
    },
    modalDescription() {
      return this.isAdditionalIncome
        ? 'Tambahan pendapatan yang dihapus tidak dapat dikembalikan'
        : 'Biaya pengurangan operasional yang dihapus tidak dapat dikembalikan.';
    },
  },

  methods: {
    closeModal() {
      this.$emit('update:isOpen', false);
      this.$emit('update:costId', null);
    },

    whichOne() {
      if (this.isAdditionalIncome) {
        this.fetchDeleteIncome();
      } else {
        this.fetchDeleteCost();
      }
    },

    async fetchDeleteCost() {
      if (this.costId !== null) {
        this.isLoading = true;

        const response = await endpoints.deleteOperationalCost(
          this.approvalId, this.costId
        );

        this.isLoading = false;

        if (response.hasError) {
          const error = response.data.issue?.message || 'Gagal saat menghapus biaya pengurangan operasional.';
          this.$toast.show(error);
          console.error(response.data.issue?.details);
        } else {
          this.$emit('action-success');
          this.$toast.show('Biaya pengurangan operasional berhasil dihapus.');
          this.closeModal();
        }
      }
    },

    async fetchDeleteIncome() {
      this.isLoading = true;

      const response = await endpoints.deleteAdditionalIncome(
        this.approvalId, this.costId
      );

      this.isLoading = false;

      if (response.hasError) {
        const error = response.data.issue?.message || 'Gagal saat menghapus biaya pendapatan.';
        this.$toast.show(error);
        console.error(response.data.issue?.details);
      } else {
        this.$emit('action-success');
        this.$toast.show('Biaya pendapatan berhasil dihapus.');
        this.closeModal();
      }
    },
  },
};
</script>
